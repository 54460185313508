

#footer {
  width: 100%;
  position: relative;
  background-color: #ff8264;
}

#footer .bloc-contenu {
  width: 1920px;
  margin: 0 auto;
  padding: 90px 30% 130px 5%;
  font-size: 0.85em;
  color: #ffffff;
}

#footer .bloc-contenu .logo1 {
  width: 32%;
  display: inline-block;
  text-align: left;
  padding-right: 10%;
}

#footer .bloc-contenu .logo2 {
  width: 66%;
  display: inline-block;
}

#footer .bloc-contenu .logo-primmea {
  width: 100%;
  max-width: 363px;
  height: auto;
}

#footer .bloc-contenu .logo2 p {
  padding-bottom: 20px;
  font-size: 0.7em;
}

#footer .bloc-contenu .logo2 img {
  width: 100%;
  max-width: 991px;
  height: auto;
}

#footer .bloc-contenu .mention {
  font-size: 0.5em;
  margin-top: 50px;
}



@media (max-width: 1900px) {
  #footer .bloc-contenu {
    width: 100%;
  }
}

@media (max-width: 1024px) {
  #footer .bloc-contenu {
    padding: 90px 5% 130px 5%;
  }
}

@media (max-width: 480px) {
  #footer .bloc-contenu {
    padding: 50px 5%;
  }

  #footer .bloc-contenu .logo1 {
    width: 90%;
    padding-right: 0;
    margin-bottom: 30px;
  }

  #footer .bloc-contenu .logo2 {
    width: 90%;
  }

  #footer .bloc-contenu .logo-primmea {
    max-width: 200px;
  }
}