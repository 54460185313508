html {
  font-size: 62.5%; }

/**** FONT FACE ****/
@font-face {
  font-family: 'VinciSans';
  src: url("/font/VinciSans.woff2") format("woff2"), url("/font/VinciSans.woff") format("woff"), url("/font/VinciSans.ttf") format("truetype"), url("/font/VinciSans.svg#VinciSans") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'VinciSansLight';
  src: url("/font/VinciSans-Light.woff2") format("woff2"), url("/font/VinciSans-Light.woff") format("woff"), url("/font/VinciSans-Light.ttf") format("truetype"), url("/font/VinciSans-Light.svg#VinciSans-Light") format("svg");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'VinciSansMedium';
  src: url("/font/VinciSans-Medium.woff2") format("woff2"), url("/font/VinciSans-Medium.woff") format("woff"), url("/font/VinciSans-Medium.ttf") format("truetype"), url("/font/VinciSans-Medium.svg#VinciSans-Medium") format("svg");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: 'VinciSansBold';
  src: url("/font/VinciSans-Bold.woff2") format("woff2"), url("/font/VinciSans-Bold.woff") format("woff"), url("/font/VinciSans-Bold.ttf") format("truetype"), url("/font/VinciSans-Bold.svg#VinciSans-Bold") format("svg");
  font-weight: bold;
  font-style: normal; }

/* CSS Document */
/**** RESET CSS ****/
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: disc; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

/**** GENERAL ****/
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

body {
  font-family: 'VinciSans', Arial, Helvetica, sans-serif;
  font-size: 60px;
  background-color: #fff; }

.clear {
  clear: both; }

sup {
  font-size: 0.6em; }

strong {
  font-family: 'VinciSansBold'; }

em {
  font-style: italic; }

.m-bottom-70 {
  margin-bottom: 70px; }

.contenu {
  width: 1920px;
  margin: 0 auto; }

.d-none {
  display: none; }

#contenu1 {
  width: 1920px;
  position: relative;
  margin: -40px auto 0 auto;
  padding: 0 30% 0 5%;
  font-size: 0.85em;
  color: #00004a; }

#contenu1 h1 {
  font-family: 'VinciSansMedium';
  font-size: 1.7em;
  margin-bottom: 65px;
  color: #00004a; }

#contenu1 h1 .couleur {
  color: #009fe3; }

#contenu1 h2 {
  font-family: 'VinciSansMedium';
  color: #ff8264;
  margin-bottom: 0.3em;
  font-size: 1.4em; }

#contenu1 h3 {
  font-family: 'VinciSansMedium';
  font-size: 1em;
  color: #ff8264;
  margin: 40px 0 10px 0; }

#contenu1 .bloc-picto {
  width: 34%;
  float: left;
  margin-bottom: 50px;
  text-align: center;
  padding: 0 20px;
  font-family: 'VinciSansMedium';
  font-size: 0.7em;
  color: #009fe3; }

#contenu1 .bloc-picto img {
  margin-bottom: 35px; }

.visuel-max {
  width: 100%;
  max-width: 3840px;
  height: auto;
  display: block; }

#contenu2 {
  width: 100%;
  position: relative;
  background-color: #ff8264;
  margin: 40px 0 0 0; }

#contenu2 .bloc-contenu {
  width: 1920px;
  margin: 0 auto;
  padding: 150px 30% 140px 5%;
  font-size: 0.85em;
  color: #ffffff; }

#contenu2 .bloc-contenu h2 {
  margin-bottom: 70px;
  font-family: 'VinciSansMedium';
  font-size: 1.3em; }

#contenu2 .bloc-contenu ul li {
  list-style-type: none;
  background-image: url("/img/puce.png");
  background-repeat: no-repeat;
  background-position: top left;
  padding: 0 0 35px 75px; }

#header {
  width: 100%;
  position: relative;
  background: url("/img/visuel-principal.jpg") top center no-repeat; }

#header .formulaire {
  background-color: #009fe3;
  width: 100%;
  max-width: 530px;
  position: fixed;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 0;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  padding: 30px;
  z-index: 999; }

#header .formulaire .titre {
  width: 100%;
  text-align: center;
  color: #1c1f41;
  font-size: 0.7em;
  font-family: 'VinciSansMedium'; }

#header .formulaire .ss-titre {
  width: 100%;
  text-align: center;
  color: #fff;
  font-size: 0.4em;
  font-family: 'VinciSansMedium';
  margin-bottom: 20px; }

#header .formulaire form input,
#header .formulaire select {
  width: 100%;
  background-color: #fff;
  border-radius: 10px;
  padding: 10px 20px;
  border: none;
  font-size: 0.4em;
  color: #009fe3;
  margin-bottom: 5px; }

#header .formulaire .mention {
  width: 100%;
  color: #fff;
  font-size: 0.4em;
  margin: 20px 0; }

#header .formulaire button {
  width: 100%;
  background-color: #fff;
  border-radius: 10px;
  padding: 10px 20px;
  border: none;
  font-size: 0.7em;
  font-family: 'VinciSansMedium';
  color: #1c1f41;
  text-align: center;
  cursor: pointer; }

#header .bloc-logo {
  width: 985px;
  height: 687px;
  background: url("/img/fond-logo.png") top right no-repeat;
  background-size: 100% auto;
  margin-left: -290px;
  padding: 0 0 0 320px; }

#header .bloc-logo img {
  margin: 114px 0 50px 0; }

#header .bloc-logo h2 {
  color: #ff8264;
  font-size: 1.6em;
  font-family: 'VinciSansMedium'; }

#header .bloc-logo h3 {
  color: #ff8264;
  font-size: 1.2em;
  font-family: 'VinciSansLight'; }

#header .bloc-prix {
  width: 746px;
  height: 626px;
  background: url("/img/fond-prix.png") top center no-repeat;
  background-size: 100% auto;
  margin: 415px 25% 0 0;
  float: right; }

#header .bloc-prix p {
  width: 100%;
  text-align: center;
  padding: 195px 75px 0 0;
  color: #fff;
  font-family: 'VinciSans';
  font-size: 0.9em; }

#header .bloc-prix p .txt1 {
  font-size: 1em; }

#header .bloc-prix p .txt2 {
  font-size: 0.9em; }

#header .bloc-prix p .txt3 {
  font-size: 2.2em; }

#header .bloc-prix p .txt4 {
  font-size: 1em;
  font-family: 'VinciSansMedium'; }

#header .bloc-prix p .txt4 small {
  font-family: 'VinciSansLight';
  font-size: 0.9em; }

.prixmoyen {
  background-color: #ff8264;
  font-family: 'VinciSansMedium';
  height: 300px;
  margin-top: 80px;
  margin-bottom: 100px; }

.prixmoyen .square {
  height: 280px;
  width: 800px;
  display: flex;
  position: relative;
  transform-origin: left center;
  margin-left: 250px;
  transform: scale(1) translateY(70px) rotate(-10deg); }

.prixmoyen .square:before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  height: 90%;
  width: 95%;
  border-radius: 50px;
  background-color: rgba(0, 0, 0, 0.7);
  transform-origin: bottom left;
  transform: rotate(3deg);
  z-index: -1; }

.prixmoyen .left {
  background-color: #e86a4c;
  width: 33%;
  color: white;
  border-bottom-left-radius: 50px;
  border-top-left-radius: 50px;
  overflow: hidden; }

.prixmoyen .left .title {
  font-size: 36px; }

.prixmoyen .left .subtitle {
  font-size: 28px;
  font-family: 'VinciSansLight';
  margin-top: 4px; }

.prixmoyen .left .bottom {
  font-size: 45px; }

.prixmoyen .left .top,
.prixmoyen .left .bottom,
.prixmoyen .right .top,
.prixmoyen .right .bottom {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 50%;
  position: relative; }

.prixmoyen .left .top:after {
  content: '';
  position: absolute;
  bottom: -7px;
  width: 100%;
  height: 14px;
  background-image: url("/img/white-dot.png");
  background-size: contain; }

.prixmoyen .right .top:after {
  content: '';
  position: absolute;
  bottom: -7px;
  width: 100%;
  height: 14px;
  background-image: url("/img/blue-dot.png");
  background-size: contain; }

.prixmoyen .right {
  background-color: white;
  width: 67%;
  color: #009fe3;
  border-bottom-right-radius: 50px;
  border-top-right-radius: 50px;
  overflow: hidden; }

.prixmoyen .right .price {
  font-size: 60px; }

.prixmoyen .logo {
  max-width: 70%;
  max-height: 70%; }

.prixmoyen .logo img {
  width: 100%;
  height: 100%;
  object-fit: contain; }

@media (max-width: 1900px) {
  .prixmoyen {
    height: 300px;
    margin-top: 80px;
    margin-bottom: 80px; }
  .prixmoyen .square {
    margin-left: 100px;
    transform: scale(1) translateY(70px) rotate(-10deg); } }

@media (max-width: 1520px) {
  .prixmoyen {
    height: 280px;
    margin-bottom: 80px;
    margin-top: 40px; }
  .prixmoyen .square {
    margin-left: 100px;
    transform: scale(0.8) translateY(60px) rotate(-10deg); } }

@media (max-width: 1280px) {
  .prixmoyen {
    height: 250px;
    margin-top: 30px; }
  .prixmoyen .square {
    margin-left: 100px;
    transform: scale(0.7) translateY(60px) rotate(-10deg); } }

@media (max-width: 1024px) {
  .prixmoyen {
    height: 250px;
    margin-bottom: 80px;
    margin-top: 30px; }
  .prixmoyen .square {
    margin-left: 100px;
    transform: scale(0.7) translateY(50px) rotate(-10deg); } }

@media (max-width: 768px) {
  .prixmoyen {
    height: 180px;
    margin-bottom: 80px;
    margin-top: 30px; }
  .prixmoyen .square {
    margin-left: 100px;
    transform: scale(0.49) translateY(-30px) rotate(-10deg); } }

@media (max-width: 640px) {
  .prixmoyen {
    margin-top: 20px; }
  .prixmoyen .square {
    margin-left: 60px;
    transform: scale(0.49) translateY(-20px) rotate(-10deg); } }

@media (max-width: 480px) {
  .prixmoyen {
    height: 120px;
    margin-bottom: 80px;
    margin-top: 0; }
  .prixmoyen .square {
    margin-left: 20px;
    transform: scale(0.4) translateY(-140px) rotate(-10deg); } }

@media (max-width: 350px) {
  .prixmoyen {
    margin-top: 0; }
  .prixmoyen .square {
    margin-left: 20px;
    transform: scale(0.37) translateY(-150px) rotate(-10deg); } }

@media (max-width: 320px) {
  .prixmoyen {
    height: 100px;
    margin-bottom: 80px;
    margin-top: 0; }
  .prixmoyen .square {
    margin-left: 20px;
    transform: scale(0.35) translateY(-150px) rotate(-10deg); } }

#footer {
  width: 100%;
  position: relative;
  background-color: #ff8264; }

#footer .bloc-contenu {
  width: 1920px;
  margin: 0 auto;
  padding: 90px 30% 130px 5%;
  font-size: 0.85em;
  color: #ffffff; }

#footer .bloc-contenu .logo1 {
  width: 32%;
  display: inline-block;
  text-align: left;
  padding-right: 10%; }

#footer .bloc-contenu .logo2 {
  width: 66%;
  display: inline-block; }

#footer .bloc-contenu .logo-primmea {
  width: 100%;
  max-width: 363px;
  height: auto; }

#footer .bloc-contenu .logo2 p {
  padding-bottom: 20px;
  font-size: 0.7em; }

#footer .bloc-contenu .logo2 img {
  width: 100%;
  max-width: 991px;
  height: auto; }

#footer .bloc-contenu .mention {
  font-size: 0.5em;
  margin-top: 50px; }

@media (max-width: 1900px) {
  #footer .bloc-contenu {
    width: 100%; } }

@media (max-width: 1024px) {
  #footer .bloc-contenu {
    padding: 90px 5% 130px 5%; } }

@media (max-width: 480px) {
  #footer .bloc-contenu {
    padding: 50px 5%; }
  #footer .bloc-contenu .logo1 {
    width: 90%;
    padding-right: 0;
    margin-bottom: 30px; }
  #footer .bloc-contenu .logo2 {
    width: 90%; }
  #footer .bloc-contenu .logo-primmea {
    max-width: 200px; } }

#header .formulaire.form-ok:after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px; }

#header .formulaire .success-message {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: .6em;
  z-index: 10;
  width: 80%;
  text-align: center; }

#header .formulaire input[type='submit'] {
  margin-right: 5px;
  cursor: pointer;
  font-size: 0.7em;
  font-family: 'VinciSansMedium';
  color: #00004a; }

/**** RESPONSIVE ****/
@media (max-width: 1900px) {
  body {
    font-size: 45px; }
  #header {
    background-size: 120%; }
  #header .formulaire {
    max-width: 28%;
    height: auto; }
  .contenu {
    width: 100% !important; }
  #header .bloc-logo {
    width: 600px;
    height: 418px;
    margin-left: 0;
    padding: 0 0 0 5%; }
  #header .bloc-logo img {
    margin: 50px 0 30px 0;
    width: 80%;
    height: auto; }
  #header .bloc-prix {
    width: 600px;
    height: 504px;
    margin-top: 330px; }
  #header .bloc-prix p {
    padding: 140px 0 0 0; }
  #contenu1 {
    width: 100%; }
  #contenu2 .bloc-contenu {
    width: 100%; }
  #contenu2 .bloc-contenu ul li {
    background-size: 1em;
    padding: 0 0 25px 65px; } }

@media (max-width: 1520px) {
  body {
    font-size: 40px; }
  #header .bloc-prix {
    width: 500px;
    height: 420px; }
  #header .bloc-prix p {
    padding: 120px 0 0 0; }
  #contenu1 .bloc-picto {
    width: 50%; }
  #contenu2 .bloc-contenu h2 {
    margin-bottom: 50px; } }

@media (max-width: 1280px) {
  body {
    font-size: 40px; }
  #header .bloc-prix {
    width: 450px;
    height: 378px;
    margin-top: 250px; }
  #header .bloc-prix p {
    padding: 100px 0 0 0; } }

@media (max-width: 1024px) {
  body {
    font-size: 35px; }
  #header .bloc-logo {
    width: 500px;
    height: 349px; }
  #header .bloc-logo img {
    margin: 30px 0;
    width: 80%;
    height: auto; }
  #header .bloc-prix {
    width: 450px;
    height: 378px;
    margin: 120px 5% 0 0; }
  #header .formulaire {
    max-width: 90%;
    position: relative;
    top: auto;
    -ms-transform: translateY(0%);
    transform: translateY(0%);
    border-radius: 20px;
    margin: 30px 5%; }
  #header .formulaire .titre {
    font-size: 0.9em; }
  #header .formulaire .ss-titre {
    font-size: 0.6em; }
  #header .formulaire form input,
  #header .formulaire select {
    font-size: 0.6em;
    margin-bottom: 5px; }
  #header .formulaire .mention {
    font-size: 0.6em; }
  #header .formulaire button {
    font-size: 0.9em; }
  #contenu1 {
    margin: 0 auto;
    padding: 0 5%; }
  #contenu2 .bloc-contenu {
    padding: 80px 5% 100px 5%; } }

@media (max-width: 768px) {
  body {
    font-size: 30px; }
  #header .bloc-logo {
    width: 365px;
    height: 255px; }
  #header .bloc-logo img {
    margin: 20px 0;
    width: 80%;
    height: auto; }
  #header .bloc-prix {
    width: 350px;
    height: 294px;
    margin: 100px 5% 0 0; }
  #header .bloc-prix p {
    padding: 80px 0 0 0; }
  #contenu1 .bloc-picto img {
    width: auto;
    height: 100px; } }

@media (max-width: 480px) {
  .none {
    display: none; }
  body {
    font-size: 25px; }
  #header {
    background-size: 230%; }
  #header .bloc-logo {
    width: 330px;
    height: 230px; }
  #header .bloc-logo img {
    margin: 15px 0;
    width: 80%;
    height: auto; }
  #header .bloc-prix {
    width: 300px;
    height: 252px;
    margin: 220px 5% 0 0; }
  #header .bloc-prix p {
    padding: 65px 0 0 0; }
  #contenu1 h1 {
    margin-bottom: 30px; }
  #contenu2 .bloc-contenu {
    padding: 50px 5%; }
  #contenu2 .bloc-contenu ul li {
    padding: 0 0 25px 40px; } }

@media (max-width: 320px) {
  body {
    font-size: 20px; }
  #header .bloc-logo {
    width: 280px;
    height: 195px; }
  #header .bloc-logo img {
    margin: 15px 0;
    width: 80%;
    height: auto; }
  #header .bloc-prix {
    width: 260px;
    height: 218px;
    margin: 180px 5% 0 0; }
  #header .bloc-prix p {
    padding: 65px 0 0 0; }
  #header .formulaire .titre {
    font-size: 1.1em; }
  #header .formulaire .ss-titre {
    font-size: 0.9em; }
  #header .formulaire form input,
  #header .formulaire select {
    font-size: 0.8em;
    margin-bottom: 5px; }
  #header .formulaire .mention {
    font-size: 0.8em; }
  #header .formulaire button {
    font-size: 1.1em; }
  #contenu1 .bloc-picto {
    font-size: 0.9em; }
  #contenu2 .bloc-contenu ul li {
    padding: 0 0 20px 35px; } }

#cookie-consent {
  display: none;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.8);
  color: black;
  font-family: Arial;
  font-size: 12px; }
  #cookie-consent.fixed {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0; }
  #cookie-consent.fixed.bottom {
    top: auto;
    bottom: 0; }
  #cookie-consent p {
    margin: 0;
    padding: 0; }
  #cookie-consent a {
    display: inline-block;
    margin-left: 8px;
    padding: 0 6px;
    color: #fff;
    border-radius: 0px;
    text-decoration: none; }
  #cookie-consent .cc-modal-inner {
    width: 90%;
    max-width: 600px;
    max-height: 90%;
    margin: 20px auto;
    padding: 0 0 92px 0;
    position: absolute;
    top: 50%;
    left: 50%;
    background: #fff;
    border-radius: 3px;
    transform: translate(-50%, -50%);
    font-size: 12px;
    line-height: 1.5; }
  #cookie-consent .cc-main {
    display: none; }
  #cookie-consent .cc-param {
    position: relative; }
  #cookie-consent .cc-header {
    padding: 20px;
    border-bottom: 1px solid #ddd;
    background-color: whitesmoke; }
  #cookie-consent .cc-body {
    display: block;
    width: 100%;
    height: auto;
    padding: 20px;
    position: relative;
    overflow-y: scroll; }
  #cookie-consent .cc-param .cc-body {
    height: calc(100vh - 510px);
    max-height: 290px;
    min-height: 250px; }
  #cookie-consent .cc-footer {
    width: 100%;
    height: 92px;
    padding: 20px;
    position: fixed;
    bottom: 0;
    left: 0;
    text-align: right;
    background-color: whitesmoke;
    border-top: 1px solid #ddd;
    transform: translate(0, 0); }
  #cookie-consent .cc-btn {
    text-align: center;
    display: inline-block;
    max-width: 240px;
    margin: 0 auto 20px auto;
    padding: 15px 20px;
    background-color: #7DB537;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
    text-transform: uppercase;
    color: #fff;
    font-size: 14px;
    letter-spacing: 0.1em;
    transition: background-color 0.2s ease-in-out; }
    .no-touchevents #cookie-consent .cc-btn:hover,
    .no-touch #cookie-consent .cc-btn:hover,
    .no-touchevents #cookie-consent .cc-btn:focus,
    .no-touch #cookie-consent .cc-btn:focus,
    .touchevents #cookie-consent .cc-btn:active,
    .touch #cookie-consent .cc-btn:active {
      background-color: #96cb54; }
    #cookie-consent .cc-btn.cc-btn-param {
      color: #7DB537; }
    #cookie-consent .cc-btn.cc-btn-done {
      max-width: 280px;
      margin: 0;
      padding: 15px 20px; }
  #cookie-consent .cc-btn-param {
    display: block;
    position: absolute;
    top: 50%;
    left: 0;
    color: #7DB537;
    transform: translate(0, -50%);
    transition: color 0.2s ease-in-out; }
    .no-touchevents #cookie-consent .cc-btn-param:hover,
    .no-touch #cookie-consent .cc-btn-param:hover,
    .no-touchevents #cookie-consent .cc-btn-param:focus,
    .no-touch #cookie-consent .cc-btn-param:focus,
    .touchevents #cookie-consent .cc-btn-param:active,
    .touch #cookie-consent .cc-btn-param:active {
      color: #96cb54; }
  #cookie-consent .cc-title {
    display: block;
    margin: 0;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: bold;
    text-align: left; }
    #cookie-consent .cc-title.cc-title--left {
      text-align: left; }
  #cookie-consent .cc-intro {
    display: block;
    padding-bottom: 20px; }
  #cookie-consent .cc-type {
    display: block;
    padding: 20px 0;
    position: relative;
    border-top: 1px solid #ddd; }
    #cookie-consent .cc-type::after {
      content: "";
      display: table;
      clear: both; }
    #cookie-consent .cc-type:first-of-type {
      border-top: none; }
    #cookie-consent .cc-type label {
      display: block;
      width: 80%; }
      #cookie-consent .cc-type label strong {
        display: block;
        margin-bottom: 10px;
        font-size: 13px;
        font-weight: 800; }
    #cookie-consent .cc-type input[type="checkbox"] {
      border-radius: 0;
      -webkit-appearance: none;
      -moz-appearance: none;
      position: absolute;
      right: 0;
      top: 20px;
      cursor: pointer;
      display: block;
      width: 44px;
      height: 22px;
      background-color: #ddd;
      border-radius: 22px;
      transform: scale(1); }
      #cookie-consent .cc-type input[type="checkbox"]:before {
        content: "";
        display: block;
        width: 18px;
        height: 18px;
        position: absolute;
        top: 2px;
        left: 2px;
        background-color: #fff;
        border-radius: 11px;
        box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
        transition: transform 0.1s ease-in-out, background-color 0.1s ease-in-out; }
      #cookie-consent .cc-type input[type="checkbox"]:after {
        content: "non";
        text-transform: uppercase;
        font-weight: bold;
        font-size: 11px;
        position: absolute;
        color: #444;
        top: 112%;
        left: 50%;
        transform: translateX(-50%); }
      [lang="en"] #cookie-consent .cc-type input[type="checkbox"]:after {
        content: "no"; }
      [lang="es"] #cookie-consent .cc-type input[type="checkbox"]:after {
        content: "no"; }
      [lang="de"] #cookie-consent .cc-type input[type="checkbox"]:after {
        content: "nicht"; }
      #cookie-consent .cc-type input[type="checkbox"]:disabled {
        opacity: 0.5;
        cursor: not-allowed; }
      #cookie-consent .cc-type input[type="checkbox"]:checked {
        background-color: #7DB537; }
        #cookie-consent .cc-type input[type="checkbox"]:checked:before {
          transform: translateX(22px); }
        #cookie-consent .cc-type input[type="checkbox"]:checked:after {
          content: "oui"; }
        [lang="en"] #cookie-consent .cc-type input[type="checkbox"]:checked:after {
          content: "yes"; }
        [lang="es"] #cookie-consent .cc-type input[type="checkbox"]:checked:after {
          content: "si"; }
        [lang="de"] #cookie-consent .cc-type input[type="checkbox"]:checked:after {
          content: "ja"; }

.cc-alert {
  display: block;
  padding: 20px 20px 20px 20px;
  background-color: #ddd;
  color: #000; }
  .cc-alert a {
    color: #000 !important;
    text-decoration: underline;
    border: none !important; }

.open-rgpd-settings {
  color: currentColor; }
