

#header .formulaire.form-ok {
}

#header .formulaire.form-ok:after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(255, 255, 255, .80);
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

#header .formulaire .success-message {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: .6em;
  z-index: 10;
  width: 80%;
  text-align: center;
}

#header .formulaire input[type='submit'] {
  margin-right: 5px;
  cursor: pointer;
  font-size:0.7em;
  font-family: 'VinciSansMedium';
  color: $color-1;
}