
#header {
  width: 100%;
  position: relative;
  background: url('/img/visuel-principal.jpg') top center no-repeat;
}

#header .formulaire {
  background-color: $color-2;
  width: 100%;
  max-width: 530px;
  position: fixed;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 0;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  padding: 30px;
  z-index: 999;
}

#header .formulaire .titre {
  width: 100%;
  text-align: center;
  color: #1c1f41;
  font-size: 0.7em;
  font-family: 'VinciSansMedium';
}

#header .formulaire .ss-titre {
  width: 100%;
  text-align: center;
  color: #fff;
  font-size: 0.4em;
  font-family: 'VinciSansMedium';
  margin-bottom: 20px;
}

#header .formulaire form input,
#header .formulaire select {
  width: 100%;
  background-color: #fff;
  border-radius: 10px;
  padding: 10px 20px;
  border: none;
  font-size: 0.4em;
  color: $color-2;
  margin-bottom: 5px;
}

#header .formulaire .mention {
  width: 100%;
  color: #fff;
  font-size: 0.4em;
  margin: 20px 0;
}

#header .formulaire button {
  width: 100%;
  background-color: #fff;
  border-radius: 10px;
  padding: 10px 20px;
  border: none;
  font-size: 0.7em;
  font-family: 'VinciSansMedium';
  color: #1c1f41;
  text-align: center;
  cursor: pointer;
}

#header .bloc-logo {
  width: 985px;
  height: 687px;
  background: url('/img/fond-logo.png') top right no-repeat;
  background-size: 100% auto;
  margin-left: -290px;
  padding: 0 0 0 320px;
}

#header .bloc-logo img {
  margin: 114px 0 50px 0;
}

#header .bloc-logo h2 {
  color: $color-3;
  font-size: 1.6em;
  font-family: 'VinciSansMedium';
}

#header .bloc-logo h3 {
  color: $color-3;
  font-size: 1.2em;
  font-family: 'VinciSansLight';
}

#header .bloc-prix {
  width: 746px;
  height: 626px;
  background: url('/img/fond-prix.png') top center no-repeat;
  background-size: 100% auto;
  margin: 415px 25% 0 0;
  float: right;
}

#header .bloc-prix p {
  width: 100%;
  text-align: center;
  padding: 195px 75px 0 0;
  color: #fff;
  font-family: 'VinciSans';
  font-size: 0.9em;
}

#header .bloc-prix p .txt1 {
  font-size: 1em;
}

#header .bloc-prix p .txt2 {
  font-size: 0.9em;
}

#header .bloc-prix p .txt3 {
  font-size: 2.2em;
}

#header .bloc-prix p .txt4 {
  font-size: 1em;
  font-family: 'VinciSansMedium';
}

#header .bloc-prix p .txt4 small {
  font-family: 'VinciSansLight';
  font-size: 0.9em;
}

