
/**** RESPONSIVE ****/

@media (max-width: 1900px) {

  body {
    font-size: 45px;
  }

  #header {
    background-size: 120%;
  }

  #header .formulaire {
    max-width: 28%;
    height: auto;
  }

  .contenu {
    width: 100% !important;
  }

  #header .bloc-logo {
    width: 600px;
    height: 418px;
    margin-left: 0;
    padding: 0 0 0 5%;
  }

  #header .bloc-logo img {
    margin: 50px 0 30px 0;
    width: 80%;
    height: auto;
  }

  #header .bloc-prix {
    width: 600px;
    height: 504px;
    margin-top: 330px;
  }

  #header .bloc-prix p {
    padding: 140px 0 0 0;
  }

  #contenu1 {
    width: 100%;
  }

  #contenu2 .bloc-contenu {
    width: 100%;
  }

  #contenu2 .bloc-contenu ul li {
    background-size: 1em;
    padding: 0 0 25px 65px;
  }

}

@media (max-width: 1520px) {

  body {
    font-size: 40px;
  }

  #header .bloc-prix {
    width: 500px;
    height: 420px;
  }

  #header .bloc-prix p {
    padding: 120px 0 0 0;
  }

  #contenu1 .bloc-picto {
    width: 50%;
  }

  #contenu2 .bloc-contenu h2 {
    margin-bottom: 50px;
  }

}

@media (max-width: 1280px) {

  body {
    font-size: 40px;
  }

  #header .bloc-prix {
    width: 450px;
    height: 378px;
    margin-top: 250px;
  }

  #header .bloc-prix p {
    padding: 100px 0 0 0;
  }


}

@media (max-width: 1024px) {

  body {
    font-size: 35px;
  }

  #header .bloc-logo {
    width: 500px;
    height: 349px;
  }

  #header .bloc-logo img {
    margin: 30px 0;
    width: 80%;
    height: auto;
  }

  #header .bloc-prix {
    width: 450px;
    height: 378px;
    margin: 120px 5% 0 0;
  }

  #header .formulaire {
    max-width: 90%;
    position: relative;
    top: auto;
    -ms-transform: translateY(0%);
    transform: translateY(0%);
    border-radius: 20px;
    margin: 30px 5%;
  }

  #header .formulaire .titre {
    font-size: 0.9em;
  }

  #header .formulaire .ss-titre {
    font-size: 0.6em;
  }

  #header .formulaire form input,
  #header .formulaire select {
    font-size: 0.6em;
    margin-bottom: 5px;
  }

  #header .formulaire .mention {
    font-size: 0.6em;
  }

  #header .formulaire button {
    font-size: 0.9em;
  }

  #contenu1 {
    margin: 0 auto;
    padding: 0 5%;
  }

  #contenu2 .bloc-contenu {
    padding: 80px 5% 100px 5%;
  }

}

@media (max-width: 768px) {

  body {
    font-size: 30px;
  }

  #header .bloc-logo {
    width: 365px;
    height: 255px;
  }

  #header .bloc-logo img {
    margin: 20px 0;
    width: 80%;
    height: auto;
  }

  #header .bloc-prix {
    width: 350px;
    height: 294px;
    margin: 100px 5% 0 0;
  }

  #header .bloc-prix p {
    padding: 80px 0 0 0;
  }

  #contenu1 .bloc-picto img {
    width: auto;
    height: 100px;
  }

}

@media (max-width: 480px) {

  .none {
    display: none;
  }

  body {
    font-size: 25px;
  }

  #header {
    background-size: 230%;
  }

  #header .bloc-logo {
    width: 330px;
    height: 230px;
  }

  #header .bloc-logo img {
    margin: 15px 0;
    width: 80%;
    height: auto;
  }

  #header .bloc-prix {
    width: 300px;
    height: 252px;
    margin: 220px 5% 0 0;
  }

  #header .bloc-prix p {
    padding: 65px 0 0 0;
  }

  #contenu1 h1 {
    margin-bottom: 30px;
  }

  #contenu2 .bloc-contenu {
    padding: 50px 5%;
  }

  #contenu2 .bloc-contenu ul li {
    padding: 0 0 25px 40px;
  }

}

@media (max-width: 320px) {

  body {
    font-size: 20px;
  }

  #header .bloc-logo {
    width: 280px;
    height: 195px;
  }

  #header .bloc-logo img {
    margin: 15px 0;
    width: 80%;
    height: auto;
  }

  #header .bloc-prix {
    width: 260px;
    height: 218px;
    margin: 180px 5% 0 0;
  }

  #header .bloc-prix p {
    padding: 65px 0 0 0;
  }

  #header .formulaire .titre {
    font-size: 1.1em;
  }

  #header .formulaire .ss-titre {
    font-size: 0.9em;
  }

  #header .formulaire form input,
  #header .formulaire select {
    font-size: 0.8em;
    margin-bottom: 5px;
  }

  #header .formulaire .mention {
    font-size: 0.8em;
  }

  #header .formulaire button {
    font-size: 1.1em;
  }

  #contenu1 .bloc-picto {
    font-size: 0.9em;
  }

  #contenu2 .bloc-contenu ul li {
    padding: 0 0 20px 35px;
  }

}