/**** FONT FACE ****/

@font-face {
    font-family: 'VinciSans';
    src: url('/font/VinciSans.woff2') format('woff2'),
    url('/font/VinciSans.woff') format('woff'),
    url('/font/VinciSans.ttf') format('truetype'),
    url('/font/VinciSans.svg#VinciSans') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'VinciSansLight';
    src: url('/font/VinciSans-Light.woff2') format('woff2'),
    url('/font/VinciSans-Light.woff') format('woff'),
    url('/font/VinciSans-Light.ttf') format('truetype'),
    url('/font/VinciSans-Light.svg#VinciSans-Light') format('svg');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'VinciSansMedium';
    src: url('/font/VinciSans-Medium.woff2') format('woff2'),
    url('/font/VinciSans-Medium.woff') format('woff'),
    url('/font/VinciSans-Medium.ttf') format('truetype'),
    url('/font/VinciSans-Medium.svg#VinciSans-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'VinciSansBold';
    src: url('/font/VinciSans-Bold.woff2') format('woff2'),
    url('/font/VinciSans-Bold.woff') format('woff'),
    url('/font/VinciSans-Bold.ttf') format('truetype'),
    url('/font/VinciSans-Bold.svg#VinciSans-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
}