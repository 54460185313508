html { font-size: 62.5%; }

$font-base:  Helvetica, sans-serif;
$font-alt:   Georgia, serif;
$font-icon:  'mcube';
$image-path: '/images/';
$ease:       cubic-bezier(.215, .61, .355, 1); // a personnaliser

$color-1: #00004a;  // navy
$color-2: #009fe3;  // blue
$color-3: #ff8264;  // red
$color-4: purple;

$color-bg:          #fff;
$color-text:        $color-1;
$color-text-light:  #fff;

$color-cancel: #bc2121;

$color-link: red;
$color-link-hover: blue;

$color-border: #ddd;

$color-facebook:    #3b5998;
$color-twitter:     #00c8fa;
$color-pinterest:   #cb2027;
$color-linkedin:    #0073b2;
$color-google-plus: #dd4b39;

$white: #fff;
$black: #000;