

.prixmoyen {
  background-color: $color-3;
  font-family: 'VinciSansMedium';


  height: 300px;
  margin-top: 80px;
  margin-bottom: 100px;
}

.prixmoyen .square {
  height: 280px;
  width: 800px;
  display: flex;
  position: relative;

  transform-origin: left center;
  margin-left: 250px;
  transform: scale(1) translateY(70px) rotate(-10deg);
}

.prixmoyen .square:before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  height: 90%;
  width: 95%;
  border-radius: 50px;
  background-color: rgba(0, 0, 0, .7);
  transform-origin: bottom left;
  transform: rotate(3deg);
  z-index: -1;
}

.prixmoyen .left {
  background-color: #e86a4c;
  width: 33%;
  color: white;
  border-bottom-left-radius: 50px;
  border-top-left-radius: 50px;
  overflow: hidden;
}

.prixmoyen .left .title {
  font-size: 36px;
}

.prixmoyen .left .subtitle {
  font-size: 28px;
  font-family: 'VinciSansLight';
  margin-top: 4px;
}

.prixmoyen .left .bottom {
  font-size: 45px;
}

.prixmoyen .left .top,
.prixmoyen .left .bottom,
.prixmoyen .right .top,
.prixmoyen .right .bottom {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 50%;
  position: relative;
}

.prixmoyen .left .top:after {
  content: '';
  position: absolute;
  bottom: -7px;
  width: 100%;
  height: 14px;
  background-image: url('/img/white-dot.png');
  background-size: contain;
}

.prixmoyen .right .top:after {
  content: '';
  position: absolute;
  bottom: -7px;
  width: 100%;
  height: 14px;
  background-image: url('/img/blue-dot.png');
  background-size: contain;
}

.prixmoyen .right {
  background-color: white;
  width: 67%;
  color: $color-2;

  border-bottom-right-radius: 50px;
  border-top-right-radius: 50px;
  overflow: hidden;
}


.prixmoyen .right .price {
  font-size: 60px;
}

.prixmoyen .logo {
  max-width: 70%;
  max-height: 70%;
}

.prixmoyen .logo img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}




@media (max-width: 1900px) {
  .prixmoyen {
    height: 300px;
    margin-top: 80px;
    margin-bottom: 80px;
  }

  .prixmoyen .square {
    margin-left: 100px;
    transform: scale(1) translateY(70px) rotate(-10deg);
  }
}

@media (max-width: 1520px) {
  .prixmoyen {
    height: 280px;
    margin-bottom: 80px;
    margin-top: 40px;
  }

  .prixmoyen .square {
    margin-left: 100px;
    transform: scale(.8) translateY(60px) rotate(-10deg);
  }
}


@media (max-width: 1280px) {
  .prixmoyen {
    height: 250px;
    margin-top: 30px;
  }

  .prixmoyen .square {
    margin-left: 100px;
    transform: scale(.7) translateY(60px) rotate(-10deg);
  }
}

@media (max-width: 1024px) {
  .prixmoyen {
    height: 250px;
    margin-bottom: 80px;
    margin-top: 30px;
  }

  .prixmoyen .square {
    margin-left: 100px;
    transform: scale(.7) translateY(50px) rotate(-10deg);
  }
}

@media (max-width: 768px) {
  .prixmoyen {
    height: 180px;
    margin-bottom: 80px;
    margin-top: 30px;
  }

  .prixmoyen .square {
    margin-left: 100px;
    transform: scale(.49) translateY(-30px) rotate(-10deg);
  }
}


@media (max-width: 640px) {
  .prixmoyen {
    margin-top: 20px;
  }

  .prixmoyen .square {
    margin-left: 60px;
    transform: scale(.49) translateY(-20px) rotate(-10deg);
  }
}


@media (max-width: 480px) {
  .prixmoyen {
    height: 120px;
    margin-bottom: 80px;
    margin-top: 0;
  }

  .prixmoyen .square {
    margin-left: 20px;
    transform: scale(.4) translateY(-140px) rotate(-10deg);
  }
}


@media (max-width: 350px) {
  .prixmoyen {
    margin-top: 0;
  }

  .prixmoyen .square {
    margin-left: 20px;
    transform: scale(.37) translateY(-150px) rotate(-10deg);
  }
}


@media (max-width: 320px) {
  .prixmoyen {
    height: 100px;
    margin-bottom: 80px;
    margin-top: 0;
  }

  .prixmoyen .square {
    margin-left: 20px;
    transform: scale(.35) translateY(-150px) rotate(-10deg);
  }
}