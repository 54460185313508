
#contenu1 {
  width: 1920px;
  position: relative;
  margin: -40px auto 0 auto;
  padding: 0 30% 0 5%;
  font-size: 0.85em;
  color: $color-1;
}

#contenu1 h1 {
  font-family: 'VinciSansMedium';
  font-size: 1.7em;
  margin-bottom: 65px;
  color: $color-1;
}

#contenu1 h1 .couleur {
  color: $color-2;
}

#contenu1 h2 {
  font-family: 'VinciSansMedium';
  color: $color-3;
  margin-bottom: 0.3em;
  font-size: 1.4em;
}

#contenu1 h3 {
  font-family: 'VinciSansMedium';
  font-size: 1em;
  color: $color-3;
  margin: 40px 0 10px 0;
}

#contenu1 .bloc-picto {
  width: 34%;
  float: left;
  margin-bottom: 50px;
  text-align: center;
  padding: 0 20px;
  font-family: 'VinciSansMedium';
  font-size: 0.7em;
  color: $color-2;
}

#contenu1 .bloc-picto img {
  margin-bottom: 35px;
}