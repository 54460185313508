
.visuel-max {
  width: 100%;
  max-width: 3840px;
  height: auto;
  display: block;
}

#contenu2 {
  width: 100%;
  position: relative;
  background-color: #ff8264;
  margin: 40px 0 0 0;
}

#contenu2 .bloc-contenu {
  width: 1920px;
  margin: 0 auto;
  padding: 150px 30% 140px 5%;
  font-size: 0.85em;
  color: #ffffff;
}

#contenu2 .bloc-contenu h2 {
  margin-bottom: 70px;
  font-family: 'VinciSansMedium';
  font-size: 1.3em;
}

#contenu2 .bloc-contenu ul li {
  list-style-type: none;
  background-image: url('/img/puce.png');
  background-repeat: no-repeat;
  background-position: top left;
  padding: 0 0 35px 75px;
}
